import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'

const RezensionPage = ({ data }) => (
  <Layout>
    <div className="flex items-center flex-wrap pt-4 pb-12">
    {data.allStrapiRezension.edges.map(document => (
    <div className="w-full md:w-1/3 xl:w-1/4 p-6 flex items-center flex-col">

                <Link to={`/${document.node.slug}`}><Img className="hover:grow hover:shadow-lg" fixed={document.node.coverimg.childImageSharp.fixed}/></Link>
                    <div className="pt-2 flex items-center justify-between">
                    <Link to={`/${document.node.slug}`} className="text-xl no-underline">{document.node.titel}</Link>
                    </div>
             
            </div>
            ))}
</div>
                    </Layout>

                    )
export default RezensionPage

export const pageQuery = graphql`  
  query RezensionQuery {
    allStrapiRezension {
      edges {
        node {
          slug
          coverimg {
            childImageSharp {
              fixed(width: 250, height: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          titel
        }
      }
    }
  }
`